.input-text-with-loader-B9sZeyNg {
  position: relative;
}

.input-text-with-loader-B9sZeyNg input[type='text'] {
  height: 25px;
  font-size: 17px;
  font-weight: normal;
  padding-left: 8px;
  transition: all .4s;
  color: #000000;
  display: block;
  width: 100%;
  border: 0;
  border-width: 1px;
  border-bottom: 1px solid var(--secondary-text-color-s);
  padding-right: 30px;
  border-radius: 0;
}

.shop-checkout .input-text-with-loader-B9sZeyNg input[type='text'] {
  height: 25px;
  font-size: 17px;
  font-weight: normal;
  border-bottom: solid;
  border-color: var(--secondary-text-color-s);
  border-width: 1px;
  padding-left: 8px;
  transition: all .4s;
}

.shop-checkout .input-text-with-loader-B9sZeyNg input[type='text']:focus,
.input-text-with-loader-B9sZeyNg input[type='text']:focus {
  border-color: var(--highlight-color);
}

.shop-checkout div.input-text-hasError input[type='text'],
.shop-checkout div.input-text-hasError input[type='text']:focus,
.input-text-hasError input[type='text'],
.input-text-hasError input[type='text']:focus {
  border-color: var(--color-error);
}

.shop-checkout div.input-text-Success input[type='text'],
.shop-checkout div.input-text-Success input[type='text']:focus,
.input-text-Success input[type='text'],
.input-text-Success input[type='text']:focus {
  border-color: var(--color-success);
}

.input-text-with-loader-label-26sLQ8IZ {
  padding-left: 0;
  color: var(--secondary-text-color-s);
  position: relative;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}

.shop-checkout .input-text-with-loader-label-26sLQ8IZ {
  padding-left: 0;
  color: var(--secondary-text-color-s);
  position: relative;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 0;
  text-align: left;
}

.input-text-with-loader-state-icon-zfEmRv5D {
  position: absolute;
  top: 50%;
  transform: translateY(4%);
  right: 10px;
  width: 20px;
  height: 20px;
}

.shop-checkout .input-text-with-loader-state-icon-zfEmRv5D {
  width: 15px;
  height: 15px;
}

.input-text-with-loader-state-icon-zfEmRv5D svg {
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
}

.shop-checkout .input-text-with-loader-state-icon-zfEmRv5D svg{
  width: 15px;
  height: 15px;
}

.input-text-error-iJxKM818 {
  color: var(--color-error);
  font-size: 10px;
  display: inline-block;
  margin-top: 5px;
}

.shop-checkout .input-text-error-iJxKM818 {
  font-size: 10px;
  display: inline-block;
  margin-top: 5px;
  position: absolute;
}

.input-text-with-loader-label-26sLQ8IZ:after {
  content: attr(data-content);
  text-transform: none;
}

.input-text-with-loader-required-1KZp3fYm .input-text-with-loader-label-26sLQ8IZ:after {
  content: '';
}

.magic-link-field .input-text-error-iJxKM818 {
  color: var(--color-error);
}
@media (max-width: 992px) {
  .input-text-with-loader-state-icon-zfEmRv5D {
    display: none;
  }
  .shop-checkout .input-text-with-loader-label-26sLQ8IZ {
    font-weight: normal;
    font-size: 10px;
  }
}
