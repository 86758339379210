.magic-link-form-1Wy3JdZ3 {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

#loginPage .magic-link-form-1Wy3JdZ3{
  padding-top: 76px;
  padding-bottom: 140px;
  padding-left: 70px;
  padding-right: 70px;
}

@media (max-width: 992px) {
  #loginPage .magic-link-form-1Wy3JdZ3{
    padding: 67px 22px 100px;
  }
}

.magic-link-form-1Wy3JdZ3 .input-text-with-loader-label-1fPtM-j4 {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
}

@media (max-width: 992px) {
  .magic-link-form-1Wy3JdZ3 {
    margin-top: 80px;
  }
}

@media (max-width: 760px) {
  .magic-link-form-1Wy3JdZ3 {
    margin-top: 50px;
  }
}

.magic-link-title-3uIM7q68 {
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
  color: #000000;
  margin-bottom: 76px;
  text-align: center;
}

.magic-link-sent .magic-link-title-3uIM7q68 {
  margin-bottom: 26px;
}

.magic-link-subtitle-qwFf8teX {
  margin-top: -60px;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 1.44;
  color: var(--secondary-text-color);
  text-align: center;
}

.magic-link-title-3uIM7q68.slide-up-1dg_Q7bm {
  opacity: 0;
  -webkit-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
  -moz-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
  -ms-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
  -o-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
  animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
}

.magic-link-field-email-3C2HPm1w {
  position: relative;
  width: 100%;
  padding: 0 30px;
}

@media (max-width: 760px) {
  .magic-link-field-email-3C2HPm1w {
    margin-left: auto;
    margin-right: auto;
  }
}

.magic-link-field-email-3C2HPm1w.slide-up-1dg_Q7bm {
  opacity: 0;
  -webkit-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) .4s forwards;
  -moz-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) .4s forwards;
  -ms-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) .4s forwards;
  -o-animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) .4s forwards;
  animation: slideUp-12D2aL0Z .6s cubic-bezier(0.215, 0.61, 0.355, 1) .4s forwards;
}

@keyframes slideUp-12D2aL0Z {
  from { opacity: 0; transform: translateY(20px); }
  to  { opacity: 1; transform: translateY(0); }
}

@-moz-keyframes slideUp-12D2aL0Z {
  from { opacity: 0; -moz-transform: translateY(20px); }
  to   { opacity: 1; -moz-transform: translateY(0); }
}

@-webkit-keyframes slideUp-12D2aL0Z {
  from { opacity: 0; -webkit-transform: translateY(20px); }
  to   { opacity: 1; -webkit-transform: translateY(0); }
}

@-ms-keyframes slideUp-12D2aL0Z {
  from { opacity: 0; -ms-transform: translateY(20px); }
  to   { opacity: 1; -ms-transform: translateY(0); }
}

@-o-keyframes slideUp-12D2aL0Z {
  from { opacity: 0; -o-transform: translateY(20px); }
  to   { opacity: 1; -o-transform: translateY(0); }
}

.magic-link-field-error-zWIhQ0Ml {
  position: absolute;
  top: 50px;
  left: 22px;
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0;
}

.magic-link-form-actions-2Db7TCfT {
  position: relative;
  padding: 40px 0 0;
  display: flex;
  justify-content: center;
  height: 86px;
}

.magic-link-form-button-1FXodsUc {
  position: absolute;
  bottom: 10px;
  font-size: 15px;
  padding: 13px 20px 13px;
  box-shadow: -2px 1px 5px 0 rgba(98, 105, 255, 0.2);
  text-transform: uppercase;
  font-weight: 400;
  width: unset;
}

@media (max-width: 992px) {
  .magic-link-form-1Wy3JdZ3 {
    margin-top: 0;
  }
  .magic-link-title-3uIM7q68 {
    font-size: 24px;
    max-width: 100%;
    text-align: center;
    line-height: 24px;
    margin-bottom: 45px;
  }
  .magic-link-field-email-3C2HPm1w {
    position: relative;
    width: 100%;
    padding: 0 0;
    margin: 0 auto;
    max-width: 286px;
  }
  .magic-link-form-actions-2Db7TCfT {
    padding: 31px 0 10px;
  }
  .magic-link-form-button-1FXodsUc {
    max-width: 286px;
    margin: 0 auto;
    line-height: 16px;
    letter-spacing: 0.3px;
    border-radius: 25px;
  }
  /*:global(.magic-link-sent) .magic-link-form {*/
  /*  padding-top: 30px;*/
  /*  padding-bottom: 141px;*/
  /*}*/
  .magic-link-sent .magic-link-title-3uIM7q68 {
    margin-bottom: 35px;
  }
}
